<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header d-flex justify-content-between">
            <div class="card-title">
              <h3 class="card-label" v-html="mainData.name"></h3>
            </div>
          </div>

          <div class="card-body">
            <div class="card-button col-4 px-0 ml-auto">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Sana"
                    append-icon="event"
                    readonly
                    @blur="getData"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="modal = false"
                    >Bekor qilish</v-btn
                  >
                  <v-btn small color="success" @click="$refs.dialog.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </div>
            <div v-if="isLoading" class="text-center mt-5">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div v-if="!isLoading">
              <table>
                <thead>
                  <tr>
                    <th>Курсаткичлар номи</th>
                    <th>Сатр коди</th>
                    <th>Хисобот даври охирига</th>
                  </tr>
                </thead>
                <!-- Active -->
                <tbody
                  class="first__tbody"
                  v-for="balansdivisionsection_set in active.balansdivisionsection_set"
                  :key="balansdivisionsection_set.id"
                >
                  <tr>
                    <td>
                      <b>
                        {{ active.name_ru }}
                      </b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="balansaccount_set in balansdivisionsection_set.balansaccount_set"
                    :key="balansaccount_set.id"
                  >
                    <td class="text-left">
                      {{ balansaccount_set.name }}
                    </td>
                    <td>
                      {{ balansaccount_set.code }}
                    </td>
                    <td
                      v-if="
                        balansaccount_set.code == '0100' ||
                        balansaccount_set.code == '0200'
                      "
                      class="blue__cell"
                    >
                      {{ balansaccount_set.total | mask }}
                    </td>
                    <td class="yellow__cell" v-else>
                      {{ balansaccount_set.total | mask }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Баланс активи буйича жами</td>
                    <td></td>
                    <td>
                      {{ balansdivisionsection_set | activeCalcTotal | mask }}
                    </td>
                  </tr>
                </tbody>
                <tbody
                  class="passive__tbody"
                  v-for="balansdivisionsection_set in passive.balansdivisionsection_set"
                  :key="balansdivisionsection_set.id"
                >
                  <tr>
                    <td>
                      <b>
                        {{ passive.name_ru }}
                      </b>
                    </td>
                    <td></td>
                    <td style="background-color: #fff"></td>
                  </tr>
                  <tr
                    v-for="balansaccount_set in balansdivisionsection_set.balansaccount_set"
                    :key="balansaccount_set.id"
                  >
                    <td class="text-left">
                      {{ balansaccount_set.name }}
                    </td>
                    <td>
                      {{ balansaccount_set.code }}
                    </td>
                    <td>
                      {{ balansaccount_set.total | mask }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Баланс пассиви буйича жами</td>
                    <td></td>
                    <td>
                      {{ balansdivisionsection_set | passiveCalcTotal | mask }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data: () => ({
    date: '',
    modal: false
  }),
  beforeCreate() {
    this.$store.dispatch('getFinanceBalance')
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mijozlar' },
      { title: 'Hisob faktura' },
      { title: 'Buhgalteriya Balansi #1' }
    ])
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return val
    },
    activeCalcTotal(list) {
      const total = list.balansaccount_set
        .filter((x) => x.code !== '0100' && x.code !== '0200')
        .map((x) => x.total)
        .reduce((a, b) => a + b, 0)
      return total
    },
    passiveCalcTotal(list) {
      const total = list.balansaccount_set
        .map((x) => x.total)
        .reduce((a, b) => a + b, 0)
      return total
    }
  },
  computed: {
    mainData() {
      const data = this.$store.state.requests.financeBalance
      return data
    },
    active() {
      var data = []
      data = this.mainData.balansdivision_set
      if (data !== undefined)
        data = this.mainData.balansdivision_set.find((x) => x.tag == 'active')
      return data
    },
    passive() {
      var data = this.mainData.balansdivision_set
      data = this.mainData.balansdivision_set.find((x) => x.tag == 'passive')
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  watch: {
    date: function (newVal, oldval) {
      if (oldval !== newVal) {
        this.$store.dispatch('getFinanceBalance', newVal)
      }
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #333;
  text-align: center;
}
.yellow__cell {
  background-color: #aeecff;
}
.blue__cell {
  background-color: rgb(255, 255, 187);
}
.first__tbody tr:last-child td:nth-child(3) {
  background-color: rgb(255, 255, 187);
}
.passive__tbody tr td:nth-child(3) {
  background-color: #aeecff;
}
.passive__tbody tr:last-child td:nth-child(3) {
  background-color: rgb(255, 255, 187);
}
</style>
